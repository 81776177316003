<i18n>
{
  "de": {
    "newUserTitle": "Benutzer erfassen",
    "firstNameLabel": "Vorname",
    "lastNameLabel": "Nachname",
    "companyNameLabel": "Firma",
    "emailLabel": "E-Mail",
    "passwordLabel": "Passwort",
    "submitButtonLabel": "Speichern",
    "errorHeader": "Beim Speichern ist ein Fehler aufgetreten"
  }
}
</i18n>

<template>
  <main-layout :ready="allUsersReady" class="admin-add-user-page">
    <template #default>
      <h1>{{ $t('newUserTitle') }}</h1>
      <div class="error-message" v-if="error">
        <h2>{{ $t('errorHeader') }}</h2>
        <p><b>{{ error }}</b></p>
        <ul v-if="errorFields">
          <li v-for="field in errorFields" v-bind:key="field.name">{{ field.name }}: {{ field.error }}</li>
        </ul>
      </div>
      <form>
        <label for="user-first-name-field">{{ $t('firstNameLabel') }}</label>
        <input id="user-first-name-field" type="text" v-model="firstName">
        <label for="user-last-name-field">{{ $t('lastNameLabel') }}</label>
        <input id="user-last-name-field" type="text" v-model="lastName">
        <label for="user-company-name-field">{{ $t('companyNameLabel') }}</label>
        <input id="user-company-name-field" type="text" v-model="companyName">
        <label for="user-email-field">{{ $t('emailLabel') }}</label>
        <input id="user-email-field" type="text" v-model="email">
        <label for="user-password-field">{{ $t('passwordLabel') }}</label>
        <input id="user-password-field" type="password" v-model="password" autocomplete="new-password">
        <button-wrapper>
          <button class="button" :disabled="!submitButtonEnabled" @click.prevent="onSubmit">{{ $t('submitButtonLabel') }}</button>
        </button-wrapper>
      </form>
    </template>
  </main-layout>
</template>

<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import MainLayout from '@/pages/layouts/MainLayout'
import AdminAllUsersMixin from '@/pages/vuex-mixins/AdminAllUsersMixin'

import { mapActions } from 'vuex'

export default {
  mixins: [
    AdminAllUsersMixin // Provides: allUsers, allUsersReady
  ],
  data () {
    return {
      firstName: null,
      lastName: null,
      companyName: null,
      email: null,
      password: null,
      error: null
    }
  },
  computed: {
    errorFields () {
      let errorFields = []
      if (this.error.message.invalidFields !== undefined) {
        for (const prop in this.error.message.invalidFields) {
          if (this.error.message.invalidFields.hasOwnProperty(prop)) {
            errorFields.push({
              'name': prop,
              'error': this.error.message.invalidFields[prop]
            })
          }
        }
      }
      return errorFields
    },
    submitButtonEnabled () {
      return Boolean(this.firstName && this.lastName && this.email && this.companyName && this.password)
    }
  },
  methods: {
    ...mapActions({
      addUser: 'users/addUser'
    }),
    async onSubmit () {
      try {
        await this.addUser({
          first_name: this.firstName,
          last_name: this.lastName,
          company_name: this.companyName,
          email: this.email,
          password: this.password
        })
        this.$router.push({ name: 'adminUsers' })
      } catch (error) {
        this.error = error
      }
    }
  },
  components: {
    ButtonWrapper,
    MainLayout
  }
}
</script>

<style>
.admin-add-user-page form {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: baseline;
  justify-content: start;
  gap: var(--spacing-m);
  column-gap: var(--spacing-l);
}

.admin-add-user-page form button {
  grid-column-end: -1;
  justify-self: start;
}

.admin-add-user-page .error-message {
  padding: var(--box-padding);
  border: var(--box-error-border);
  max-width: 800px;
}

.admin-add-user-page .error-message h2 {
  color: var(--error-color);
}

</style>
